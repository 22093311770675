/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import $ from 'jquery';

console.log('app.js loaded');

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

$(function() {    
    //DETECT GOOD FILTER
    let nowHour = new Date().getHours();
    let filters = document.querySelectorAll('.filtreOneDay');
    filters.forEach(filter => {
        let start = filter.dataset.start;
        let end = filter.dataset.end;
        if(nowHour >= start && nowHour <= end)
            filter.click();
    });
    //PLAYER VIDEO SECTIONTITLE HOME
    $('.imgVideoInputIcon').on('click', (e) => {
        $('#modalVideo').show();
    });

    $('.closePlayerVideoHome').on('click', (e) => {
        $('#modalVideo').hide();
        jwplayer("fp-hlsjs").stop();
    });
    //CURSOR 
    $(document).on('mousemove' , (e) => {
        if(window.innerWidth > 820)
            $('.imgCursor').css({'display':'flex', 'left': e.clientX+"px", 'top': e.clientY+"px"});
    });
    //MENU BURGER
    $('#openrightnav').on('click', () => {
        let _screen_width = $(window).width();
        if (_screen_width <= 768) {
            $('.nav-right').css('right', '0');
            $('.nav-right').css('width', '100%');
        } else {
            $('.nav-right').css('right', '0px');
            $('.nav-right').css('width', '620px');
        }
    });
    $('#closerightnav').on('click', () => {
        let _screen_width = $(window).width();
        if (_screen_width <= 768) {
            $('.nav-right').css('right', '-100%');
            $('.nav-right').css('width', '-100%');
        } else {
            $('.nav-right').css('right', '-620px');
            $('.nav-right').css('width', '0');
        }
    });
    $('body').on('click', (e) => {
        if(e.target.classList[0] === 'nav-right' || e.target.id === 'openrightnav' || e.target.id === 'closerightnav')
            return false;
        else {
            let _screen_width = $(window).width();
            if (_screen_width <= 768) {
                $('.nav-right').css('right', '-100%');
                $('.nav-right').css('width', '-100%');
            } else {
                $('.nav-right').css('right', '-620px');
                $('.nav-right').css('width', '0');
            }
        }
    });
    //ICON SITE SELECTION
    let siteList = $('.siteSelection')[0].classList;
    $('.siteSelectionIcon').on('click', () => {
        if(siteList.length === 1)
            return $('.siteSelection').addClass('showSiteSelection');
        else
            return $('.siteSelection').removeClass('showSiteSelection');
    });
    $('body').on('click', (e) => {
        if(e.target.classList[0] === 'siteSelectionIcon' || e.target.classList[0] === 'siteSelection')
            return false;
        else {
            $('.siteSelection').removeClass('showSiteSelection');
        }
    });
    let myEfficientFn = debounce(function() {
        $('.btnSend').trigger( "click" );
    }, 250);

    $('.btnSendForm').on('click', () => { 
        myEfficientFn();
    });

    $('.subGame').on('click', ()=> {
        $('#concours').fadeIn();
    });
    $('#sendGame').on('click', ()=> {
        $('#concours').fadeOut();
    });
    
    let date =  new Date().toISOString();
    let rgpdLimit = localStorage.getItem('rgpdMediawanChaineDateLimit');

    if(rgpdLimit !== null) {
        if(date > rgpdLimit)
            $('#rgpdModal').fadeIn(1000);
        else if(date < rgpdLimit)
            $('#rgpdModal').hide();
    } else {
        $('#rgpdModal').fadeIn(1000);
    }
    //MODAL RGPD
    $('.containerModal').on('click', (e) => {
        //console.log(e.target.id)
        if(e.target.className === 'containerModal') {
            $('.containerModal').fadeOut();
        }
        if(e.target.id === 'refuse') {
            localStorage.setItem('rgpdMediawanChaineDateLimit', new Date(new Date().setMonth(new Date().getMonth()+13)).toISOString());
            $('.containerModal').fadeOut();
        }
        if(e.target.id === 'accept') {
            localStorage.setItem('rgpdMediawanChaineDateLimit', new Date(new Date().setMonth(new Date().getMonth()+13)).toISOString());
            $('.containerModal').fadeOut();
        }
    });

    //FOOTER
    $('.btn-footer').on('click', (e) => {
        $('#newsletters').fadeIn();
    });
    //SORT BY
    $('.sortIcon').on('click', () => {
        let sortClass = $('.sortBySelection')[0].classList;
        if(sortClass.length === 1) {
            $('.sortBySelection').addClass('showSortBy');
        }else {
            $('.sortBySelection').removeClass('showSortBy');
        }
    });
    $( window ).on('resize' , function() {
        let widthContainer = $(".containerPoster").width();    
        $(".descriptionJaquette").css( "width", widthContainer - 92 +"px" );    
        $(".descriptionJaquette").css( "margin-left", 0 );    
        $(".one.showProg.active").find(".descriptionJaquette");
    // $(".one.showProg.active").css( "margin-left", 0 );   

        let infoBulle = $(".one.showProg.active").find(".descriptionJaquette");
        if($(".one.showProg").hasClass("active")){
            let positionElem = $(".one.showProg.active").position();        
            let leftPosition = (positionElem.left - ($("body").width() * 0.10) - 40);
            infoBulle.css("left", ( (leftPosition > 0) ? "-"+leftPosition : 5  ) +"px");
            $(".one.showProg.active").css( "margin-bottom", infoBulle.height()+125+"px"); 
        }
        
    });
    //GRID TV
    $(document).on('click', '#close', function(e){                
        $(".one").removeClass("active");
        $(".one").css( {"margin-bottom": 0});  
        $(".descriptionJaquette").css( {"display":"none","margin-left": 0 });     
    });
    $(".one").on('click', function(e){
        if( 
            ( e.target.classList[0] === "imgJaquette" ) 
            || ( e.target.classList[0] === "titleJaquette" ) 
            || ( e.target.classList[0] === "horraireJaquette" ) 
        ) 
        {                
            let widthContainer = $(".containerPoster").width();    
            let div  = $("<div></div>");
            let title = $("<p id='titleJaquette'></p>").text($(this).data("title"));
            let synopsis = $("<p></p>").text($(this).data("synopsis"));   
            let infoBulle = $(".containerPoster").find(`.descriptionJaquette[data-indexDesc='${Math.ceil($(this).data("index"))}']`);
            var img = $('<img id="close">'); 

            $(".one").removeClass("active");
            $(this).addClass("active");
            $(".one").css("margin-bottom", 0+"px");
            $(".descriptionJaquette").css( "display", "none" );    
            $(".descriptionJaquette").css( "width", widthContainer - 92 +"px" );    
            img.attr('src', "/images/icon_close.png");
            let positionElem = $(this).position();
            infoBulle.empty();
            div.append(img,title, synopsis);
            infoBulle.append(div);
            infoBulle.toggle();
            let leftPosition = (positionElem.left - ($("body").width() * 0.10) - 40);
            infoBulle.css("left", ( (leftPosition > 0) ? "-"+leftPosition : 0  ) +"px");
            $(this).css( "margin-bottom", infoBulle.height()+125+"px"); 
        }
    });

    $('.playIcon').on('click', (e) => {
        let targetSliderHomeVideo = document.querySelector('#'+e.target.dataset.selectmodal + ' div ' + ' .fp-hlsjs');
        let targetSliderHomeVideoFile;
        if(targetSliderHomeVideo !== null) {
            $('#'+e.target.dataset.selectmodal).show();
            console.log(targetSliderHomeVideo)
            targetSliderHomeVideoFile = targetSliderHomeVideo.dataset.src;
            console.log(targetSliderHomeVideoFile)
            console.log(targetSliderHomeVideo.id)
            
            jwplayer(targetSliderHomeVideo.id).setup({
                file: targetSliderHomeVideoFile,
                "aspectratio": "16:9",
                "autostart": false,
                "cast": {
                },
                "controls": true,
                "primary": "html5",
                "stagevideo": false,
                "stretching": "uniform",
                "width": "100%",
                "ph": 1,
                "pid": "V64tMklE",
            });
        } else {
            $('#'+e.target.dataset.selectmodal).show();
        }
    });
    $('.closePlayerVideo').on('click', (e)=> {
        $('#'+e.target.dataset.selectmodal).hide();
        let iframe = document.querySelector('#'+e.target.dataset.selectmodal + ' iframe');
        let abVideo = document.querySelector('#'+e.target.dataset.selectmodal+ ' div ');
        let targetAb = abVideo.children[0].id;

        if(iframe !== null) {
            let iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        } else if(targetAb !== null ) {
            jwplayer(targetAb).stop()
        }
    });

    $('.returnActu').on('click', (e) => {
        e.preventDefault();
        //console.log('here')
        window.location.href = document.referrer;
    });

    let programs = document.querySelectorAll('.one');      

    $('.filtreOneDay').on('click', (e) => {
        $('.filtreOneDay').removeClass("active");
        $(".one").removeClass("active");
        $(".one").css( {"margin-bottom": 0});  
        $(".descriptionJaquette").css( {"display":"none","margin-left": 0 });  
        let target = e.target;
        target.classList = "filtreOneDay active";
        let date = new Date();
        if($("#date-picked").html() !== ""){
            date = new Date($("#date-picked").attr("data-year")+"-"+$("#date-picked").attr("data-month")+"-"+$("#date-picked").attr("data-day"));
        }
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        let filterStart = new Date(year, month, day, target.dataset.start, 0, 0);
        let filterEnd = new Date(year, month, day, target.dataset.end, 0, 0);
        //console.log(filterStart);
        //console.log(filterEnd);
        if(target.dataset.nextday === "1")
            filterEnd = new Date(year, month, day+1, target.dataset.end, 0, 0)

        programs.forEach(obj => {
            obj.classList.remove('showProg')
            let progDate = new Date(obj.dataset.startdate);
            if(progDate >= filterStart && progDate <= filterEnd)
                obj.classList.add('showProg')
        });
    });

    $('.calendarInput').on('click', (e) => {
        $('.menuOneDay').hide();
        $('.containerPoster').hide();
        $('.wrapperCalendar').show();
    })

    $(document).on('click', '.date-picker', function(e){  
        $(".one").removeClass("active");
        $(".one").css( {"margin-bottom": 0});  
        $(".descriptionJaquette").css( {"display":"none","margin-left": 0 });  
        $('.wrapperCalendar').hide();       
        
        let target = e.target;     
        $("#date-picked").attr( {"data-day": target.dataset.date,"data-month": target.dataset.month,"data-year": target.dataset.year  });
        let newDate = target.dataset.year+"-"+target.dataset.month+"-"+target.dataset.date;            
        let date = new Date(newDate);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        let filterStart = new Date(year, month, day, 6,0,0);
        let filterEnd = new Date(year, month, day+1, 6,0,0);
        programs.forEach(obj => {
            obj.classList.remove('showProg')
            let progDate = new Date(obj.dataset.startdate);
            if(progDate >= filterStart && progDate <= filterEnd)
                obj.classList.add('showProg')
        });
        $('.menuOneDay').show();
        $('.filtreOneDay').removeClass("active");
        $('.menuOneDay').find('.filtreOneDay[data-start=06]').addClass("active");
        $('.filtreOneDay[data-start=06]').trigger( "click" );
        $('.containerPoster').show();

    })
    $('.closeCalendar').on('click', (e) => {
        $('.wrapperCalendar').hide();
        $('.menuOneDay').show();
        $('.containerPoster').show();
    });
    $('.menuOneDay').find('.filtreOneDay[data-start=06]').trigger( "click" );
});
